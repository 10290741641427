$(document).ready(function() {
   
    $(function(){
		$('#MenuTop').slicknav();
	});

    $('.popup').fancybox();
	$('a.gallery').fancybox({
        padding : 0
    });
	$('a.galleria').fancybox();
	$("a.popupper").fancybox({
		'titleShow'			: false,
		'width'				: 500,
		'height'			: 300,
		'autoScale'			: false,
		fitToView   		: false,
		autoSize    		: false,
		'transitionIn'		: 'none',
		'transitionOut'		: 'none',
		'type'				: 'iframe'
	});

	$('.burgher').click(function(){
		$('#MenuHidden').slideToggle();
	})

	$(".Slider__Home").bxSlider({
		auto: true
	});


	 $('a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        
      
        var target = this.hash,
            menu = target;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top+2
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });

	$('.open').click(function(){
		elemento = $(this).data('elemento');
		console.log(elemento);
		if($('.area-open.'+elemento).is(":hidden") ) {
			$('.area-open').slideUp('fast');
			$('.area-open.'+elemento).slideToggle('fast');
		} else {
			$('.area-open.'+elemento).slideToggle('fast');
		}
	});

	$('.menu-shop-mobile').click(function(){
		$('#MenuHidden').slideToggle();
		$('#MenuTopShop').slideToggle();
		$('.burgher').hide();
		$('.chevron').show();
	});

	$('.menu-collection-mobile').click(function(){
		$('#MenuHidden').slideToggle();
		$('#MenuTopCollection').slideToggle();
		$('.burgher').hide();
		$('.chevron').show();
	});

	$('.chevron').click(function(){
		$('.burgher').show();
		$('.chevron').hide();
		$('#MenuHidden').slideToggle();
		if($('#MenuTopCollection').is(":visible")) {
			$('#MenuTopCollection').slideToggle();
		}
		if($('#MenuTopShop').is(":visible")) {
			$('#MenuTopShop').slideToggle();
		}
	});
});
 
function ajaxPost(url,target,idform,params,callback){

	$.ajax({
	  url: url + "?" + params,
	  context: target,
	  type: 'POST',
	  data: $(idform).serialize(),
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}


function ajaxGet(url,target,params,callback){

	$.ajax({
	  // url: url + "?" + params,
	  context: target,
	  type: 'GET',
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}
